import React, { forwardRef } from 'react';
import { Box, Typography, Container } from '@mui/material';
import logo from "../../assets/img/logo.svg";
import { useSelector } from 'react-redux';

const TestPdfTemplate = forwardRef((props, ref) => {
    const { vacancyText } = useSelector(
        (state) => state?.jobDescriptionFileUploadReducer);
    const vacancyTextData =
        vacancyText[0]?.vacancyText !== undefined &&
            vacancyText[0]?.vacancyText !== null
            ? vacancyText[0]?.vacancyText
            : vacancyText[0];

    const { templateData } = props;
    console.log("vacancyTex654tData", templateData);
    return (
        <Container
            ref={ref}
            maxWidth="lg" style={{
                backgroundColor: 'white',
                padding: '30px',
                borderRadius: '8px',
                display: 'none'
            }}
        >
            <Box textAlign="center" marginBottom="40px">
                <img src={logo} alt="Effectus HR Logo" style={{ marginBottom: '20px' }} />
                <Typography variant="h3" fontWeight="bold" color="#333">
                    Job Vacancy Text
                </Typography>
            </Box>

            {/* Introduction Section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                Introduction
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    Have you worked with us before? Then most of what is in this document is already familiar to you. You can then skip ahead to 3.4 Proposed recruitment text. We would like to receive a response to this as soon as possible so that the recruitment campaign can begin. Thanks in advance!
                </Typography>
                <Typography variant="body1" color="#833B91" fontWeight="bold" marginBottom="10px">
                    The recruitment profile is an important element of the recruitment campaign, which takes place mainly online. After all:
                </Typography>
                <Typography variant="body1" color="#E5383B" fontStyle="italic" marginBottom="10px">
                    The days when recruitment was all about a good network and lots of phone calls are behind us.
                </Typography>
                <Typography variant="body1" color="#333">
                    After all, we now have all the facts and figures we need to make a campaign effective. Especially online. So for this vacancy, we rely on facts, not assumptions or feelings. Data-driven recruitment, in other words. We measure, test and apply that data in the recruitment campaign. Starting with the job title and recruitment text.
                </Typography>
            </Box>

            {/* Choice of Job Title Section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                Choice of job title
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="h6" color="#333" marginBottom="10px" fontWeight="bold">
                    {vacancyTextData?.Job_Title}
                </Typography>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.Trending_Titles?.map((ele, index) => (
                            <div key={index}>{Object.values(ele)[0]}</div>
                        ))
                    }
                </Typography>


                <Typography variant="h6" color="#333" marginBottom="10px" fontWeight="bold">
                    Storytelling
                </Typography>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {vacancyTextData?.Storytelling}
                </Typography>


                <Typography variant="h6" color="#333" marginBottom="10px" fontWeight="bold">
                    Neuromarketing principles
                </Typography>

                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.neuromarketing_principles?.map((ele, index) => {
                            const [key, value] = Object.entries(ele)[0];
                            return <div key={index}>» {` ${key}: ${value}`}</div>
                        })
                    }
                </Typography>

            </Box>

            {/* Social Media Section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                Social media template
            </Typography>
            {/* <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    Have you worked with us before? Then most of what is in this document is already familiar to you. You can then skip ahead to 3.4 Proposed recruitment text. We would like to receive a response to this as soon as possible so that the recruitment campaign can begin. Thanks in advance!
                </Typography>
                <Typography variant="body1" color="#833B91" fontWeight="bold" marginBottom="10px">
                    The recruitment profile is an important element of the recruitment campaign, which takes place mainly online. After all:
                </Typography>
                <Typography variant="body1" color="#E5383B" fontStyle="italic" marginBottom="10px">
                    The days when recruitment was all about a good network and lots of phone calls are behind us.
                </Typography>
                <Typography variant="body1" color="#333">
                    After all, we now have all the facts and figures we need to make a campaign effective. Especially online. So for this vacancy, we rely on facts, not assumptions or feelings. Data-driven recruitment, in other words. We measure, test and apply that data in the recruitment campaign. Starting with the job title and recruitment text.
                </Typography>
            </Box> */}
          {
    templateData?.map((ele, index) => {
        return (
            <Box key={index}>
                <Box className='p-30' style={{ position: 'relative' }}>
                    {/* Render description inside Typography without wrapping divs */}
                    <Typography variant='h4' className='fs-10 fw-500 text-color-c2'>
                        {ele?.description}
                    </Typography>
                </Box>
                {/* Keep other content outside of Typography */}
                <Box className='combined-box mt-auto'>
                    <Box className='glider-img-box'>
                        <img
                            className='main-img'
                            crossOrigin='anonymous'
                            src={ele?.image}
                            alt="template"
                        />
                    </Box>
                </Box>
            </Box>
        );
    })
}

{/* Who are we section */}

{Object.entries(vacancyTextData?.Company_Overview)?.map(([key]) => (
                    <div key={key}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {key === "Who_Are_We" && "Who are we?"}
                       
                      </Typography>
                      <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.Company_Overview?.[key]
                    }
                </Typography>
            </Box>
                    </div>
                  ))}

                  

                  {/* Role Details section */}

                  {Object.entries(vacancyTextData?.Role_Details).map(([key, value]) => (
                    <div key={key}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {key === "Role_Overview"
                          ? "What does the position entail?"
                          : key === "Motivation_for_Role"
                          ? "Why are you going to do this?"
                          : key ===
                            "What_activities_can_you_expect_during_a_working_day?"
                          ? "What activities can you expect during a working day?"
                          : key}

                        
                      </Typography>
                      <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.Role_Details?.[key]
                    }
                </Typography>
            </Box>
                    </div>
                  ))}

            {/* The job requirements section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                The job requirements:
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.Criteria?.map((ele, index) => {
                            const [key, value] = Object.entries(ele)[0];
                            return <div key={index}>»{` ${key}: ${value}`} </div>
                        })
                    }
                </Typography>
            </Box>

            {/* These characteristics suit you: section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                These characteristics suit you:
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.fitting_traits?.map((ele, index) => (
                            <div key={index}>» {ele}</div>
                        ))
                    }
                </Typography>
            </Box>

            {/* These are your employment conditions: section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                These are your employment conditions:
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.Employment_Condition?.map((ele, index) => {
                            const [key, value] = Object.entries(ele)[0];
                            return <div key={index}>»{` ${key}: ${value}`} </div>
                        })
                    }
                </Typography>
            </Box>

            {/* Just a few reasons to want to work with us section */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                Just a few reasons to want to work with us
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {
                        vacancyTextData?.reasons_to_join?.map((ele, index) => (
                            <div key={index}>» {(ele)}</div>
                        ))
                    }
                </Typography>
            </Box>

            {/* This is how you can apply for this position */}
            <Typography variant="h5" fontWeight="bold" color="#05164D" marginBottom="20px" style={{ background: "#FEF4F8", height: '120px', paddingTop: '40px', paddingLeft: '40px' }}>
                This is how you can apply for this position
            </Typography>
            <Box marginBottom="30px" style={{ padding: '20px', borderRadius: '8px' }}>
                <Typography variant="body1" color="#333" marginBottom="10px">
                    {vacancyTextData?.application_instructions}
                </Typography>

            </Box>


        </Container>
    );
})

export default TestPdfTemplate;