import React, { useEffect, useState } from "react";
import {
  chatbotData,
} from "../../redux/action/chatbotAction";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";
import Header from "../../components/header";
import { useLocation, useNavigate } from "react-router";
import Loader from "../../components/Loader";
import ChatbotUpdate from "./chatbotUpdate";
import { createChatbotSession, getNextQuestion, getPrevQuestion } from "../../redux/action/chatbotActions";
import Utils from "../../utils/reduxUtils";

const ChatbotNewQuestions = () => {
  const currentQuestion = useSelector(
    (state) => state?.newChatbot?.currentQuestion
  );
 const location=useLocation();
 const getDraftedSessionId=location.state?.sessionId;
  const sessionId = useSelector(state => state?.newChatbot?.newSession?._id);
  const profileInfo = useSelector(state => state?.profileReducer.profileInfoDetails)
  const isAuthenticated = localStorage?.getItem("accessToken");
  const [currentSessionId, setCurrentSessionId] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const intialMessagesState=[
    { id: 1, text: "For which client do you want to generate the text? ", sender: "bot" },
  ]
  let [messages, setMessages] = useState([]);
  const [sortedPdfAnswers, setSortedPdfAnswers] = useState('');
  const [isPdfUploading, setPdfUploading] = useState(false);
  const [goingBack, setGoingBack] = useState(false);
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    setPdfUploading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL_FRONTEND}ai/getAnswersFromDocument/${sessionId}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${isAuthenticated}`,
        }
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      setSortedPdfAnswers(responseData?.answer);
      setPdfUploading(false);
      return responseData;
    } else {
      return "Error uploading file.";
    }
  };
  
  useEffect(() => {
    setCurrentSessionId(sessionId);
  }, [sessionId])

  const handleOnSendMessage = async (file, msg) => {
    if (messages.length === 1) {
    const clientDescription=profileInfo?.clientInfo?.find((ele)=>ele?.name===msg)?.description;
      dispatch(createChatbotSession({
        "companyName": msg,
        "companyDescription": `${profileInfo?.type==="agency"?clientDescription:'-'}`
      }));
      return;
    }
    
    let tempCurrentQuestion = {}
    if (file) {
     const fileUploadResponse=await uploadFile(file);
      tempCurrentQuestion = { ...currentQuestion }
      dispatch(getNextQuestion(tempCurrentQuestion, currentSessionId));
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages?.length + 1,
          text:fileUploadResponse?.question ,
          sender: "bot",
        },
      ]);
      return;
    }

    tempCurrentQuestion = { ...currentQuestion, answer: msg };
    if (msg === "Proceed Now") {
      handleQuestionPayload();
      return;
    }
   dispatch(getNextQuestion(tempCurrentQuestion, currentSessionId));
  };
  

 useEffect(() => {
if(messages?.filter(ele=>ele?.text===currentQuestion?.question)?.length===0 &&
      currentQuestion?.question!==undefined && currentQuestion?.question!=="" ){
        let newMessages=[
          ...messages,         
          {
            id: messages?.length + 1,
            text: currentQuestion?.question,
            sender: "bot",
          },
        ]
        setMessages(newMessages);
      }      
  }, [currentQuestion])
 

 
 
  useEffect(()=>{
    dispatch({ type: Utils.ActionName.RESET_CURRENT_QUESTION });
    if(!getDraftedSessionId){
      setMessages(intialMessagesState);
   }
    },[])

  const handleBack = () => {
    if (currentQuestion?.inputType.includes("select")) {
      setMessage("");
    }
    dispatch(getPrevQuestion(currentSessionId));

   setGoingBack(true);
    
  }

  useEffect(() => {
    if (goingBack) {
     let questionMsgId = messages?.filter(x => x.text === currentQuestion?.question)[0].id;
      setMessages((prevMessages) => [
        ...prevMessages.filter(x => x.id <= questionMsgId)
      ]);
      console.log('me465ssage');
      if((currentQuestion?.key!=="slogan" && currentQuestion?.answer!=="Skip") || (currentQuestion?.key==="slogan" && currentQuestion?.answer!=="Skip")){
        setMessage(currentQuestion?.answer);
       }

      setGoingBack(false);
    }
    console.log('messag555e');
    if((currentQuestion?.key!=="slogan" && currentQuestion?.answer!=="Skip") || (currentQuestion?.key==="slogan" && currentQuestion?.answer!=="Skip")){
      setMessage(currentQuestion?.answer);
     }
    // setMessage(currentQuestion?.answer)
  }, [currentQuestion?.question])

  const handleQuestionPayload = async() => {
    if (isAuthenticated === null) {
      navigate('/pricing');
      return;
    }
    
    const payload = { sessionId: sessionId }
    await dispatch(chatbotData(payload,setLoading,navigate));
    setMessages([]);
    navigate("/job/vacancies", { state: { loading } });
  };

  return (
    <>
      {isPdfUploading ? (
        <Loader isPdfUploading={isPdfUploading} />
      ) : (
        <>
          <Header />
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="mb-40 mt-40"
              >
                <Typography
                  variant="h2"
                  className="fs-38 fw-600 text-center text-orange"
                >
                  Generate Job Vacancy Text
                </Typography>
                <Typography
                  variant="h2"
                  className="fs-16 fw-400 text-center m-auto"
                  sx={{ maxWidth: "720px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magn{" "}
                  {loading ? <CircularProgress /> : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ChatbotUpdate
                  onSendMessage={handleOnSendMessage}
                  messages={messages}
                  setMessages={setMessages}
                  currentQuestion={currentQuestion}
                  handleBack={handleBack}
                  message={message}
                  setMessage={setMessage}
                  sortedPdfAnswers={sortedPdfAnswers}
                  handleContinue={handleQuestionPayload}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ChatbotNewQuestions;
