import Utils from "../../utils/reduxUtils";

const {JOBDESCRIPTIONFILEUPLOAD,CHATBOTDATA,VACANCYID,CHATBOTDATA_LOADING,TONE_OF_VOICE,STORE_CHATBOT_PAYLOAD, SHARE_POST, COLOR_SELECTION, EDITED_VACANCY_TEXT,SOCIAL_MEDIA_TEMPLATE,SHARE_VACANCY_TEXT,BEAUTIFY_TEMPLATE_DATA,GOTO_VACANCYTEXT_FROM_DASHBOARD} = Utils.ActionName;

const initialState= {
    jobDescriptionFileUploadDetails:[],
    vacancyText:[],
    storeChatbotPayload:[],
    socialMediaText:[],
    chatbotDataLoading: false,
    shareVacancyText:[],
    beautifiedText:[],
    editedTextData:[],
    vacancyId:'',
    selectedColor:'#4543a8'
}

export const jobDescriptionFileUploadReducer=(state=initialState,action)=>{
  console.log("actionpayload", action?.payload); 
  switch(action.type){
    case JOBDESCRIPTIONFILEUPLOAD:
        return {...state,
          jobDescriptionFileUploadDetails: [...state.jobDescriptionFileUploadDetails, ...action.payload]};
    case CHATBOTDATA_LOADING:
            return { ...state, chatbotDataLoading: action.payload };      
    case CHATBOTDATA:
      // const vacancyId = action?.payload?._id || '';  
      console.log("chatbotSolution",action.payload);
      return {
        ...state,
        vacancyText: [action.payload], 
        // vacancyId,
        chatbotDataLoading: false
      };
    case VACANCYID:
      const vacancyId=action?.payload?._id||"";
      return {...state, vacancyId}
    case SHARE_VACANCY_TEXT:
      console.log("action12",action.payload);
      return {
        ...state,
        shareVacancyText: action.payload, 
        chatbotDataLoading: false
      };
      case GOTO_VACANCYTEXT_FROM_DASHBOARD:
      console.log("action12",action.payload);
      return {
        ...state,
        vacancyText: [action.payload], 
        chatbotDataLoading: false
      };
      
    case EDITED_VACANCY_TEXT:
      console.log("action12",action.payload);
      return {
        ...state,
        vacancyText: [{vacancyText:action.payload}], // Replace the old data
        chatbotDataLoading: false
      };
      // case STORE_CHATBOT_PAYLOAD:
      //   return {
      //     ...state,
      //     storeChatbotPayload:[action.payload],
      //     chatbotDataLoading: false
      //   }
    case TONE_OF_VOICE:
        console.log("action12",action.payload);
        return {
          ...state,
          vacancyText: [{vacancyText:action.payload}], // Replace the old data
          chatbotDataLoading: false
        };
    case SOCIAL_MEDIA_TEMPLATE:
      return {
        ...state,
        socialMediaText:[action.payload],
        chatbotDataLoading:false
      }
      case BEAUTIFY_TEMPLATE_DATA:
      return {
        ...state,
        vacancyText:[{vacancyText:action.payload}],
        chatbotDataLoading:false
      }
    case SHARE_POST:
      return {
        ...state,
        chatbotDataLoading:false
      }

    case COLOR_SELECTION:
      console.log("action12",action.payload);
      return {
        ...state,
        selectedColor: action.payload, 
        // chatbotDataLoading: false
      }; 

     default:
        return state;   
  }
}

