import Utils from "../../utils/reduxUtils";

export const getDashboardData = (params) => {
    return (dispatch) => {
        dispatch({type:Utils.ActionName.DASHBOARD_DATA_LOADING})
        // return new Promise((resolve, reject) => {
            Utils.api.getApiCall(
                Utils.EndPoint.getDashboardData(params),
                (resData) => { // Success callback
                    if (resData?.status === 200) {
                       
                        dispatch({ type: Utils.ActionName.GET_DASHBOARD_DATA, payload: resData.data });
                        // resolve(resData); 
                    } else {
                        dispatch({ type: Utils.ActionName.RESET_DASHBOARD_DATA, payload: resData.data });
                        // reject(resData);
                    }
                },
                (error) => { 
                    // reject(error); 
                },
                false, 
                "" 
            );
        // });
    };
};

// xyz(diapatch)
