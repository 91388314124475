import Utils from "../../utils/reduxUtils";

const {
    GET_DASHBOARD_DATA,
    RESET_DASHBOARD_DATA,
    DASHBOARD_DATA_LOADING
} = Utils.ActionName;

const initialState = {loading:false};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return { ...state, ...action.payload, loading:false };
        case RESET_DASHBOARD_DATA:
            return { ...initialState };
        case  DASHBOARD_DATA_LOADING:
            return {...state,loading:true}   
        default:
            return state;
    }
};