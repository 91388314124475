import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Select, MenuItem, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { companyInfo, getProfileInfo } from '../../redux/action/profileAction';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { datepickerStyle, getStyles, MenuProps } from './helper';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const ChatbotUpdate = ({ onSendMessage, messages, setMessages, currentQuestion, handleBack, message, setMessage, isPdfUploaded, handleContinue }) => {
  const userData = useSelector(
    (state) => state?.authenticationReducer?.data?.user);
  const dispatch = useDispatch();
  const profileInfo = useSelector(state => state?.profileReducer.profileInfoDetails)
  const questionLoading = useSelector(state => state?.newChatbot.questionLoading);
  const today = new Date().toISOString().split('T')[0];
  const [multiMessage, setMultiMessage] = useState("");
  const [customAnswers, setCustomAnswer] = useState("")
  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  // let completeClientInfo;

  const [completeClientInfo, setCompleteClientInfo] = useState([]);

  let convertCompanyInfoToArray = [profileInfo?.companyInfo];
  convertCompanyInfoToArray.push({ name: 'Custom...' })


  console.log("c56ompleteClientInfo", completeClientInfo)

  const [clientDetails, setClientDetails] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const chatEndRef = useRef(null);
  const isUserAuthenticated = localStorage?.getItem("accessToken");
  const fileUploadRef = useRef(null);
  const sendBtn = useRef(null);
  const theme = useTheme();
  const moveToCurrentChatbotQuestion = useRef(null);
  const navigate = useNavigate();

  console.log('messa654ges', message);

  useEffect(() => {

    // if(currentQuestion?.key==="website_url"){
    //   const result=convertCompanyInfoToArray[0]?._id? convertCompanyInfoToArray[0]?.url:'';

    //   setMessage(result);
    //   setUpdate(!update);
    //   console.log('result55',result);
    // }
    if (currentQuestion?.inputType === "select" && currentQuestion?.answer !== "") {
      console.log("entered opportunities question");
      if (!currentQuestion.options?.includes(currentQuestion.answer)) {
        console.log("entered opportunities question");
        setMultiMessage("Custom...")
        setCustomAnswer(currentQuestion.answer)
      }
    }
    if (currentQuestion?.key === "special_questions") {
      setMultiMessage("");
    }

    if (currentQuestion?.inputType === 'select') {
      if (currentQuestion?.answer !== "" && !currentQuestion?.options?.includes(currentQuestion?.answer)) {
        setMultiMessage("Custom...");
      }
      else {
        setMultiMessage(currentQuestion?.answer);
      }
    }

    if (currentQuestion?.inputType === 'multi-select' && currentQuestion.key === "Top_Competencies") {

      let matchingOptions = currentQuestion?.options?.filter(ele => currentQuestion?.answer?.includes(ele));
      console.log('matchingOptions', matchingOptions);
      if (currentQuestion?.answer !== "" && matchingOptions?.length == 0) {
        setSelectedCompetencies(["Custom..."]);
        setMultiMessage("Custom...");
        setCustomAnswer(currentQuestion?.answer);
        return;
      }

      let joinedOptions = [...matchingOptions].join(",")
      setCustomAnswer(currentQuestion?.answer?.slice(joinedOptions?.length + 1));
      setMultiMessage(joinedOptions);
      console.log('matchingOptions', matchingOptions);
      if (matchingOptions?.length === 2 && !matchingOptions.includes("Custom...")) {
        matchingOptions.push("Custom...");
        setMultiMessage(matchingOptions?.join(","))
        setSelectedCompetencies(matchingOptions);
      }
      else {
        setSelectedCompetencies(matchingOptions);
      }

    }

    if (currentQuestion?.inputType === 'multi-select' && currentQuestion.key === "opportunities") {
      let matchingOptions = currentQuestion?.options?.filter(ele => currentQuestion?.answer?.includes(ele));
      console.log("multiMessage", multiMessage);
      let joinedOptions = [...matchingOptions].join(",")
      console.log('joinedOptions', matchingOptions.length);

      let currentCustomAnswerUpdated = multiMessage?.includes("Custom...") && currentQuestion?.answer !== "" && matchingOptions.length === 0 ? currentQuestion?.answer : currentQuestion?.answer?.slice(joinedOptions?.length + 1)
      console.log("matchingOptions6688r", currentCustomAnswerUpdated);

      setCustomAnswer(currentCustomAnswerUpdated);

      if (joinedOptions?.length !== 0 || currentQuestion?.answer === "") {
        if (currentCustomAnswerUpdated !== "")
          setMultiMessage(`${joinedOptions},Custom...`);
        else
          setMultiMessage(joinedOptions);
      }
      else {
        setMultiMessage("Custom...")
      }

    }
  }, [currentQuestion?.question])
  console.log("multiMessag5555e", currentQuestion);

  // useEffect(() => {
  //   dispatch(getProfileInfo(userData?._id));
  //   if(profileInfo){
  //     if(profileInfo.clientInfo.length===0){
  //       toast.error("Please update client information in profile before proceeding");
  //       navigate('/profile');
  //     }
  //   }
  // }, [])

  useEffect(() => {
    moveToCurrentChatbotQuestion?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }, [messages])

  useEffect(() => {
 let convertCompanyInfoToArray = [profileInfo?.companyInfo];
    const initialClientInfo = profileInfo?.type === 'agency' ? profileInfo?.clientInfo|| [] : convertCompanyInfoToArray || [];
    console.log("initialClientInfo66",initialClientInfo);
    if (!initialClientInfo?.some(ele => ele?.name === 'Custom...')) {
     setClientDetails([...initialClientInfo, { name: 'Custom...' }])
    }
    else {
      setClientDetails([...initialClientInfo, { name: 'Custom...' }])
    }
  }, [])



  const handleFileChange = async (e) => {
    // if (processing) return;
    const selectedFile = e.target.files[0];
    console.log("selectedFile", selectedFile)
    setFile(e.target.files[0]);
    setMessage(e.target.files[0].name);
    setProcessing(true);
    if (message?.trim() || selectedFile) {
      await handleMessageExport(e.target.files[0].name, selectedFile);
      setMessage("");
      setFile(null);
    }
    setProcessing(false);
  };

  const handleSelectChange = async (option) => {
    console.log("option", option);

    if (option?.includes("Custom...")) {
      setMultiMessage('Custom...');
      setCustomAnswer("");
      return;
    }
    currentQuestion.answer = option;
    if ((option?.trim() || file) && currentQuestion?.key === "special_questions") {
      await handleMessageExport(option, file);
      return;
    }
    setMultiMessage(option);
    if (currentQuestion?.inputType === 'date') {
      setMessage(option);
      return;
    }

    //Adding logic for moving on to next question directly once the user select one option from the inputType select questions except for Custom option
    if (!option.includes('Custom...')) {
      await handleMessageExport(
        option,
        file);
    }

    setMessage('');
    if (processing) return;
    setProcessing(true);
    setProcessing(false);
  };

  const handleMessageExport = async (message, file) => {

    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages?.length + 1, text: message, sender: "user" },
    ]);
    setMessage(message);
    setMultiMessage("");
    await onSendMessage(file, message);
    setMessage("");
    scrollToBottom();
  };

  const handleMultiTextMessage = (e) => {
    setCustomAnswer(e.target.value)
    if (multiMessage.includes("Custom...")) {
      setCustomAnswer(e.target.value)
    } else {
      setMultiMessage(e.target.value);

    }
  }

  const handleTextMessage = (e) => {
    setMessage(e.target.value);
  }

  const handleSkip = async () => {
    let skipMessage = "Skip";
    await handleMessageExport(skipMessage, file);
  }

  const handleCustomSelect = async () => {
    if (currentQuestion?.inputType === "select" || currentQuestion?.inputType === "multi-select" || messages?.length == 1) {
      console.log('multiMessa465ge', multiMessage);
      setProcessing(true);

      if ((currentQuestion?.key !== "Top_Competencies") && (multiMessage?.trim() || file || currentQuestion?.answer || multiMessage === "")) {
        console.log("enteredmultiMessage")
        let removeCustom = multiMessage.includes("Custom...") ? (multiMessage.split(',')
          .filter(skill => skill !== "Custom...")
          .join(',')) : "";

        const exportMessage = multiMessage?.includes("Custom...")
          ? removeCustom ? `${removeCustom},${customAnswers}` : customAnswers
          : multiMessage || currentQuestion?.answer;

        await handleMessageExport(
          exportMessage,
          file);
      }

      else if (currentQuestion?.key === "Top_Competencies") {


        const newArray = [...selectedCompetencies];
        const customIndex = newArray.indexOf("Custom...");
        if (customIndex > -1) {
          const customValue = customAnswers;
          newArray[customIndex] = customValue;
          console.log('newArray', newArray)
          setMultiMessage(newArray.join(','));
        }


        console.log("selectedCompetencies", selectedCompetencies)

        let removeCustom = multiMessage.includes("Custom...") ? (selectedCompetencies
          .filter(skill => skill !== "Custom...")
          .join(',')) : multiMessage
        console.log('removeCustom', removeCustom);
        if (multiMessage !== "") {
          await handleMessageExport(
            multiMessage.includes("Custom...")
              ? `${removeCustom},${customAnswers}`
              : multiMessage, file);

          setProcessing(false);
          setMultiMessage('');
          setMessage('');
          setSelectedCompetencies([]);
          setCustomAnswer("");
        }
      }

    }
  }
  console.log('multiMessage52', message);
  const handleSendMessage = async () => {
    setProcessing(true);
    if ((message?.trim() && message !== "") || currentQuestion?.answer !== "") {
      await handleMessageExport(message, file);
    }
    setProcessing(false);
  };

  const handleCompetencyChange = (event) => {
    const { target: { value } } = event;
    if (value.length > 3) {
      return;
    }
    console.log('competencyTest', value);
    setSelectedCompetencies(
      typeof value === 'string' ? value.split(',') : value
    );
    console.log("value55", value)
    const tempMessage = value.join(",");
    console.log("temp55", tempMessage)
    setMultiMessage(tempMessage);
  };

  const handleClientSelection = (event) => {
    const { value } = event.target;
    setSelectedClient(value);
    setMultiMessage(value);
  }


  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  const handleMultiSelectChange = (option) => {

    if (multiMessage?.includes(option)) {
      console.log("entered if", multiMessage);
      const tempArr = multiMessage?.split(",");
      console.log("tempArr", tempArr);
      const index = tempArr?.indexOf(option);
      console.log("index", index);
      if (index > -1) {
        tempArr?.splice(index, 1);
      }
      setMultiMessage(tempArr.join(","));
      return;
    }

    else if (multiMessage && currentQuestion?.key === "opportunities") {
      console.log("entered else");
      const tempMessage = multiMessage + "," + option;
      const options = tempMessage.split(",");
      console.log("tempMessage", tempMessage)
      setMultiMessage(tempMessage);
    }
    else {
      setMultiMessage(option);
    }
  };


  return (
    <div>
      <Box className="chatbot">
        <Box className="messages">
          <p style={{ fontSize: "20px" }}>
            {`Welcome back, ${userData?.fullName}! Let's start creating a new job vacancy`}
          </p>
          {messages?.map((msg) => (
            <Box
              key={msg.id}
              className={`message ${msg.sender}-message`}
              ref={chatEndRef}
            >
              <Box className="message-content">
                <Box className="message-header">
                  <img
                    src={`https://cdn-icons-png.flaticon.com/512/21/21104.png`}
                    alt={msg.sender}
                    className="message-avatar"
                    ref={moveToCurrentChatbotQuestion}
                  />
                  <span className="message-username">
                    {msg.sender === "user"
                      ? `${isUserAuthenticated === null
                        ? "Guest"
                        : userData?.fullName
                      }`
                      : "Virtual Assistant"}
                  </span>
                </Box>
                {/* {
                  msg.sender === 'bot' ? <p className="message-text" ref={chatEndRef}>
                    {msg?.text}
                  </p> : <pre className="message-text" ref={chatEndRef}>
                    {msg?.text}
                  </pre>multiMessage.includes("Custom...")

                } */}
                <p className="message-text" ref={chatEndRef}>
                  {msg?.text}
                </p>


                {
                  msg?.id == 1 &&
                  <Select
                    className="chatbot-dropdown"
                    sx={{ width: "100%", border: "1px solid #F89B53" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={selectedClient}
                    onChange={handleClientSelection}

                    displayEmpty
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>Select Client</em>;
                      }
                      return selected;
                    }}
                    MenuProps={MenuProps}
                  >
                    {clientDetails?.map((ele, index) => (
                      <MenuItem
                        key={index}
                        value={ele?.name}
                        style={getStyles(ele?.name, selectedClient, theme)}
                      >
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                }

                {currentQuestion?.inputType === "select" &&
                  msg.sender === "bot" &&
                  msg.text ===
                  currentQuestion.question && (
                    <Box>
                      {currentQuestion?.options?.map((option, index) => {
                        console.log('option900', option)
                        return (
                          <Box
                            key={`option-${index}`}
                            onClick={() => handleSelectChange(option)}
                            className={`select-option ${(multiMessage.includes("Custom...") && multiMessage === option ? "selected" : !multiMessage.includes("Custom...") && currentQuestion?.answer === option) ? "selected" : ""
                              }`}
                          >
                            {option}
                          </Box>
                        );
                      })}
                    </Box>
                  )}

                {currentQuestion?.inputType === "date" && msg.sender === "bot" && msg.text ===
                  currentQuestion?.question &&
                  <Box>

                    <Box
                      key={`option-${1}`}
                      onClick={() => handleSelectChange(currentQuestion.options[0])}
                      className={`select-option ${currentQuestion?.answer === "No deadline" ? "selected" : ""}`}
                      sx={{ width: '50%' }}
                    >
                      {currentQuestion.options[0]}
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DemoContainer components={['DatePicker']} sx={{ marginLeft: '55px', marginTop: '5px' }}>
                        <DatePicker
                          label="Basic date picker"
                          // value={dayjs(currentQuestion?.answer || today)} 
                          value={currentQuestion.answer ? dayjs(currentQuestion.answer, "DD-MM-YYYY") : dayjs(today)}
                          onChange={(newValue) => {
                            const formattedDate = newValue ? newValue.format('DD-MM-YYYY') : '';
                            handleSelectChange(formattedDate);
                          }}
                          format="DD-MM-YYYY"
                          sx={datepickerStyle}
                          renderInput={(params) => <TextField {...params} style={{ width: "40px " }} />}
                          minDate={dayjs(today)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>


                  </Box>
                }

                {currentQuestion?.inputType === "multi-select" &&
                  msg.sender === "bot" &&
                  currentQuestion?.key === "Top_Competencies" &&
                  msg.text ===
                  currentQuestion?.question && (
                    <Box sx={{ margin: "10px 0px 0px 50px" }}>
                      <Select
                        className="chatbot-dropdown"
                        sx={{ width: "100%", border: "1px solid #F89B53" }}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedCompetencies}
                        onChange={handleCompetencyChange}

                        displayEmpty
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return <em>Select Competencies</em>;
                          }
                          return selected.join(', ');
                        }}
                        MenuProps={MenuProps}
                      >
                        {currentQuestion?.options.slice(1).map((name, index) => (
                          <MenuItem
                            key={index}
                            value={name}
                            style={getStyles(name, selectedCompetencies, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}

                {currentQuestion?.inputType === "multi-select" &&
                  msg.sender === "bot" && currentQuestion?.key === "opportunities" &&
                  msg.text ===
                  currentQuestion?.question && (
                    <Box>
                      {currentQuestion.options.map((option, index) => (
                        <Box
                          key={`option-${index}`}
                          onClick={() => handleMultiSelectChange(option)}
                          className={`select-option ${multiMessage?.includes(option) ? "selected" : ""
                            }`}
                        >
                          {option}
                        </Box>
                      ))}
                    </Box>
                  )}
              </Box>


            </Box>
          ))}


          {currentQuestion?.prevKey !== "start" && messages?.length !== 1 &&
            <button
              className="continue-button secondary-btn"
              onClick={() => {
                handleBack();
              }}
            >
              Go to Previous Question
            </button>
          }
          {
            currentQuestion?.key === "slogan" &&
            <button
              style={{ marginLeft: "25px" }}
              className="continue-button secondary-btn"
              onClick={() => {
                handleSkip();
              }}
            >
              Skip
            </button>
          }

          {currentQuestion?.key === "end" && (
            <div className="message user-message">
              <div className="message-content">
                <button
                  className="continue-button primary-btn primary-btn-outline"
                  onClick={() => {
                    handleContinue();
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </Box>
        <Box className="input-container">
          <Box className="file-input-container">
            <input
              ref={fileUploadRef}
              type="file"
              title=""
              style={{ zIndex: 1 }}
              onChange={(e) => {
                handleFileChange(e);
              }}
              className="file-input"
              disabled={currentQuestion?.inputType === "file" ? false : true}
            />
            {currentQuestion?.inputType === 'file' ? (
              <Tooltip title="Please click on it to upload a file" arrow>
                <IconButton
                  sx={{ margin: 0, padding: 0, zIndex: 2 }}
                  onClick={() => fileUploadRef.current?.click()}
                >
                  <PhotoLibraryIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <PhotoLibraryIcon />
            )}
          </Box>
          {(currentQuestion?.inputType === "select" || currentQuestion?.inputType === "multi-select" || messages?.length === 1) ? (
            <textarea
              type="text"
              // value={multiMessage.includes("Custom...") && !isPdfUploaded ? customAnswers :
              //   currentQuestion?.answer !== "" && currentQuestion?.answer !== undefined ? multiMessage :
              //     ""}
              value={multiMessage.includes("Custom...") && !isPdfUploaded ? customAnswers : multiMessage
              }
              onChange={handleMultiTextMessage}
              placeholder="Type your message..."
              className="message-input"
              disabled={!multiMessage.includes("Custom...")}
            />
          ) : (
            <textarea
              type="text"
              value={message}

              onChange={(e) => {
                handleTextMessage(e);
              }}
              placeholder="Type your message..."
              className="message-input"
              disabled={
                currentQuestion?.inputType === "file" || currentQuestion?.inputType === "date" || currentQuestion?.key === "end"
              }
            />
          )}
          <Button
            ref={sendBtn}
            onClick={() => {
              currentQuestion?.inputType === "select" || currentQuestion?.inputType === "multi-select" || messages?.length === 1
                ? handleCustomSelect()
                : handleSendMessage();
            }}
            disabled={currentQuestion?.key === "special_questions" || currentQuestion?.key === "end"}
            className="send-button p-0 text-orange bg-none"
            sx={{ minWidth: "Fit-content" }}
          >
            <SendOutlinedIcon />
          </Button>
        </Box>
      </Box>

    </div>
  )
}

export default ChatbotUpdate;
