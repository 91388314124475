import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const architypes = [
    {
        
        section: "Job Title",
        paragraphs: [
          "Software Developer",
        ],
        bulletpoints: [
         
        ],
        },
    {
        
    section: "StoryTelling",
    paragraphs: [
      "Things happen by doing them. That is what we at NHL Stenden believe. We give our students the opportunity to develop their talents optimally. In a modern research environment, they themselves give direction to their studies and professional growth. We have strong ties with companies and institutions in the region, but also across the border. As an international multi-campus university of applied sciences, we encourage our students to look beyond their own field of study to develop new knowledge and participate in innovative projects.",
      "We create a meeting place where flows of knowledge and cultures come together and where new connections with the work field are created. In an ambitious way, NHL Stenden provides knowledge that works!",
    ],
    bulletpoints: [
     
    ],
    },
  {

    section: "Who are we?",
    paragraphs: [
      "Things happen by doing them. That is what we at NHL Stenden believe. We give our students the opportunity to develop their talents optimally. In a modern research environment, they themselves give direction to their studies and professional growth. We have strong ties with companies and institutions in the region, but also across the border. As an international multi-campus university of applied sciences, we encourage our students to look beyond their own field of study to develop new knowledge and participate in innovative projects.",
      "We create a meeting place where flows of knowledge and cultures come together and where new connections with the work field are created. In an ambitious way, NHL Stenden provides knowledge that works!",
    ],
    bulletpoints: [
      "Student-driven learning in a modern research environment.",
      "Strong connections with regional and international companies.",
      "Encouraging cross-disciplinary and innovative learning.",
      "Fostering a multicultural and collaborative educational community.",
      "Creating practical knowledge applicable to the real-world workforce.",
    ],
  },
  {
    section: "Job requirements",
    paragraphs: [
      "We are looking for a candidate with advanced academic qualifications in the field of Cyber Security. Ideally, you will hold a master’s degree or PhD, allowing you to contribute to our cutting-edge programs with a solid foundation in this critical domain.",
      "In addition to your academic background, you should possess a strong professional network in Cyber Security, both on a national and international scale. Fluency in English, both spoken and written, is essential for this role, as you will be required to communicate effectively in a global environment.",
    ],
    bulletpoints: [
      "You have a master’s degree or PhD in the field of Cyber Security.",
      "You have a relevant (inter)national network in the world of Cyber Security.",
      "You have an excellent command of the English language both orally and in writing.",
    ],
  },
  {
    section: "Acquisition",
    paragraphs: [
      "We are consciously and confidently working together with Effectus -HR to fill this vacancy. That is why Effectus -HR has been granted exclusivity for this vacancy. Given their 93% fulfillment rate, we are convinced that they will also find a more than suitable candidate for this vacancy. Acquisition in response to this vacancy is therefore neither necessary nor desirable.",
    ],
    bulletpoints: [
      
    ],
  },
];


export const handleCopyContent = (vacancyTextData,contentRef2) => {
  let contentToCopy = "";

  for (const [key, value] of Object.entries(vacancyTextData)) {
    if (key === "Job_Title") {
      contentToCopy += `Job Title: ${value}\n\n`;
    } else if (key === "Trending_Titles") {
      contentToCopy += `Trending Titles:\n`;
      value.forEach((item) => {
        for (const [title, description] of Object.entries(item)) {
          contentToCopy += `  - ${title}: ${description}\n`;
        }
      });
      contentToCopy += "\n";
    } else if (
      key === "Elements_of_values" ||
      key === "neuromarketing_principles"
    ) {
      contentToCopy += `${key.replace(/_/g, " ")}:\n`;
      value.forEach((item) => {
        for (const [subKey, subValue] of Object.entries(item)) {
          contentToCopy += `  - ${subKey}: ${subValue}\n`;
        }
      });
      contentToCopy += "\n";
    } else if (key === "Tone_of_voice") {
      continue;
    } else if (typeof value === "object" && !Array.isArray(value)) {
      contentToCopy += `${key.replace(/_/g, " ")}:\n`;
      for (const [subKey, subValue] of Object.entries(value)) {
        contentToCopy += `  - ${subKey}:\n`;
        subValue.forEach((item) => {
          contentToCopy += `    * ${item}\n`;
        });
      }
      contentToCopy += "\n";
    } else if (Array.isArray(value)) {
      contentToCopy += `${key.replace(/_/g, " ")}:\n`;
      value.forEach((item) => {
        contentToCopy += `  - ${item}\n`;
      });
      contentToCopy += "\n";
    } else {
      contentToCopy += `${key.replace(/_/g, " ")}: ${value}\n\n`;
    }
  }

  const content2 = contentRef2.current.innerText;
  const combinedContent = `${contentToCopy}\n${content2}`;

  navigator.clipboard
    .writeText(combinedContent)
    .then(() => {
      toast.success("Copied to clipboard");
      console.log("Content copied to clipboard successfully!");
    })
    .catch((err) => {
      console.error("Failed to copy content: ", err);
    });
};

//handle Tone Of Voice Selection
export const handleToneOfVoice = (tov,vacancyTextData,toneOfVoice,dispatch,handleClose) => {
  const payload = {
    structured_output: JSON.stringify(vacancyTextData),
    tone_of_voice: "",
  };
  switch (tov) {
    case "friendlyTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "Friendly" }));
      handleClose();
      break;
    case "innovativeTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "innovative" }));
      handleClose();
      break;
    case "professionalTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "professional" }));
      handleClose();
      break;
    case "inspirationalTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "inspirational" }));
      handleClose();
      break;
    case "informativeTone":
      dispatch(toneOfVoice({ ...payload, tone_of_voice: "informative" }));
      handleClose();
      break;
    default:
      break;
  }
};


//handle share via email

export const handleShareEmail = (vacancyId,vacancyTextData) => {
  const subject = "Job Vacancy Information";
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const baseUrl = url.origin;
  const jobDetailUrl = `${baseUrl}/sharejob/${vacancyId}`;
  let body = `
          Job Title: ${vacancyTextData?.["Job_Title"]}\n
          We have an exciting opportunity for you!\n\n
          Please copy and paste the following link into your browser to read the full job description: ${jobDetailUrl}
      `;
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);
  console.log('encodedSubject',encodedSubject,encodedBody);
  const mailtoLink = `mailto:to@example.com?subject=${encodedSubject}&body=${encodedBody}`;
  window.location.href = mailtoLink;
};

//New VacancyText Object

export const newVacancyText={
  "Job_Title": "Senior HR Manager",
  "Trending_Titles": [
    {
      "HR Business Partner": "This title emphasizes strategic partnership with business units, enhancing neuromarketing by aligning HR strategies with business goals to foster a cohesive and productive work environment."
    },
    {
      "Talent Acquisition Leader": "Focusing on talent acquisition highlights the proactive approach to sourcing and nurturing top talent, appealing to the audience's desire for growth and innovation."
    },
    {
      "Employee Engagement Director": "This title underscores the importance of employee satisfaction and engagement, resonating with candidates who value a positive and inclusive workplace culture."
    }
  ],
  "Storytelling": "The office hums with the quiet determination of a team driven by purpose. As the Senior HR Manager at Mamaearth, your day begins with a meeting where you gather insights from department heads. The challenge? To align human capital with the company's mission of creating safe, toxin-free products for families. You navigate through complex recruitment strategies, ensuring each new hire embodies the values of quality, wellness, and safety. Your empathetic approach fosters an environment where employees feel valued and heard, driving engagement and innovation. In the ever-evolving landscape of HR, you stay ahead by integrating the latest trends in employee wellness and compliance. Your strategic vision transforms challenges into opportunities, making Mamaearth not just a workplace but a community that thrives on transparency and trust. As you close your laptop at day's end, you reflect on the impact of your role—not just in shaping careers but in contributing to a brand that empowers families worldwide. Ready to lead with empathy and expertise? Join us at Mamaearth and be part of something meaningful.",
  "Tone_of_voice": "Caring and Informative",
  "Elements_of_values": [
    {
      "Quality": "Quality appeals to candidates by promising high standards in both product offerings and workplace practices, enhancing trust and reliability."
    },
    {
      "Wellness": "Wellness attracts individuals who prioritize a healthy work-life balance, offering them assurance of personal well-being being a company priority."
    },
    {
      "Safety": "Safety resonates with the innate human need for security, ensuring candidates feel protected in their work environment."
    }
  ],
  "neuromarketing_principles": [
    {
      "Empathy Mapping": "Used in storytelling to create an emotional connection by showcasing empathy in leadership, encouraging candidates to envision themselves in a supportive role."
    },
    {
      "Scarcity Principle": "Highlighted in the urgency to apply by emphasizing limited availability of such impactful roles, motivating quick decision-making."
    },
    {
      "Social Proof": "Demonstrated through company achievements and employee testimonials, enhancing credibility and attractiveness of the job offer."
    }
  ],
  "Company_Overview": {
    "Who_Are_We": "Mamaearth is a pioneering brand dedicated to creating safe, toxin-free products for babies and parents. With a commitment to transparency and honesty, we source the best ingredients globally. Our company culture thrives on empowering employees by valuing their feedback in organizational strategies. Recognized for our innovative approach in product development, Mamaearth stands as a beacon of quality and trust in the market."
  },
  "Role_Details": {
    "Role_Overview": "As a Senior HR Manager at Mamaearth, you'll develop comprehensive HR strategies aligned with business objectives, lead recruitment efforts to attract top talent who share our core values, oversee performance management systems for continuous development, ensure compliance with labor laws while fostering an inclusive culture, and drive employee engagement through innovative wellness programs.",
    "Motivation_for_Role": "This role offers you the chance to make a significant impact on both organizational success and employee well-being. You'll have autonomy in strategic decision-making while shaping company culture. The fulfillment comes from knowing your efforts contribute to creating safe products for families worldwide.",
    "What_activities_can_you_expect_during_a_working_day?": "A typical day involves collaborating with department heads to align HR strategies with business goals, managing recruitment processes to ensure top talent acquisition, overseeing performance evaluations, implementing wellness initiatives, and maintaining compliance with labor laws—all while fostering an inclusive workplace culture."
  },
  "Criteria": [
    {
      "Years of Experience Required": "7 to 10 years"
    },
    {
      "Relevant Experience": "Strategic HR leadership roles focusing on talent management, recruitment, employee relations"
    },
    {
      "Min Education Background": "Bachelor’s degree"
    },
    {
      "Options for Field of Study": "Human Resources, Business Administration or Organizational Development"
    },
    {
      "Skills and Certifications": "Senior Professional in Human Resources (SPHR) or SHRM-SCP preferred"
    },
    {
      "Top Competencies": "Leadership; Strategic Thinking; Communication; Problem-solving"
    },
    {
      "Competency Details": ""
    },
    {
      "Language Phrasing": ""
    }
  ],
  "fitting_traits": [
    "The ideal candidate is assertive yet empathetic, able to lead initiatives while understanding employee needs.",
    "Adaptability is key; thrive in dynamic environments where change is constant.",
    "A focus on results ensures you drive projects forward efficiently.",
    "Strong communication skills help articulate ideas clearly across all levels.",
    "Problem-solving abilities enable you to turn challenges into opportunities for growth."
  ],
  "reasons_to_join": [
    "Mamaearth is an industry leader known for its commitment to safe products.",
    "Innovative branches within the company drive creativity and cutting-edge solutions.",
    "Flexibility in role execution offers autonomy and values initiative.",
    "Involvement in prestigious projects contributes to global change.",
    "Abundant opportunities for career growth highlight our commitment to development."
  ],
  "application_instructions": "If you're excited about this opportunity and see yourself fitting these requirements, apply via our company portal by September 10th. We encourage self-assessment against our criteria before applying. For questions or more information about the application process or position details, reach out through our contact page.",
  "Employment_Condition": [
    {
      "Employment Type": "Permanent"
    },
    {
      "Reporting Structure": ""
    },
    {
      "Job Level": "Senior"
    },
    {
      "Compensation": "Competitive"
    },
    {
      "Compensation Currency": "USD"
    },
    {
      "Salary Range": "90,000 - 130,000 USD per year"
    },
    {
      "Working Hours": "Flexible working hours"
    },
    {
      "Job Location": "Noida with remote work arrangements available"
    },
    {
      "Benefits and Perks": "Paid time off; Employee wellness programs; Leadership training"
    },
    {
      "Additional Benefits": "Holistic health improvement programs"
    },
    {
      "How to Apply": "Apply via our company portal"
    },
    {
      "Application Deadline": "September 10th"
    }
  ]
}


