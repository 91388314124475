import Utils from "../../utils/reduxUtils";

const {
    GET_CURRENT_SESSION,
    GET_PREVIOUS_QUESTIONS,
    LOADING_SESSION,
    RESET_CHATBOT_SESSION,
    RESET_CURRENT_QUESTION
} = Utils.ActionName;

const initialState = {
    questionLoading: false,
    
};

export const chatbotReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_SESSION:
           return { ...state, ...action.payload, questionLoading: false };
        case GET_PREVIOUS_QUESTIONS:
            return { ...state, ...action.payload, questionLoading: false };
        case LOADING_SESSION:
            return { ...state, questionLoading: action.payload };
        case RESET_CHATBOT_SESSION:
            return {...initialState };
        case RESET_CURRENT_QUESTION:
            console.log('state55',state);
            return {}; 
        default:
            return state;
    }
};