const ActionName = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  FORGOT: "FORGOT",
  VERIFYOTP: "VERIFYOTP",
  RESENDOTP: "RESENDOTP",
  RESET_PASSWORD: "RESET_PASSWORD",
  PROFILE:"PROFILE",
  COMPANYINFO:'COMPANYINFO',
  PROFILEINFO:"PROFILEINFO",
  CLIENTINFO:"CLIENTINFO",
  JOBDESCRIPTIONFILEUPLOAD:"JOBDESCRIPTIONFILEUPLOAD",
  CHATBOTDATA:"CHATBOTDATA",
  CHATBOTDATA_LOADING:"CHATBOTDATA_LOADING",
  TONE_OF_VOICE:"TONE_OF_VOICE",
  STORE_CHATBOT_PAYLOAD:"STORE_CHATBOT_PAYLOAD",
  EDITED_VACANCY_TEXT:"EDITED_VACANCY_TEXT",
  SOCIAL_MEDIA_TEMPLATE:"SOCIAL_MEDIA_TEMPLATE",
  SHARE_VACANCY_TEXT:"SHARE_VACANCY_TEXT",
  GET_SUBSCRIPTIONS:"GET_SUBSCRIPTIONS",
  GET_SUBSCRIPTIONS_BY_ID:"GET_SUBSCRIPTIONS_BY_ID",
  GENERATE_PAYMENT_INTENT:"GENERATE_PAYMENT_INTENT",
  PAYMENT_SUCCEDED:"PAYMENT_SUCCEDED",  
  BEAUTIFY_TEMPLATE_DATA:"BEAUTIFY_TEMPLATE_DATA",
  VACANCYID:"VACANCYID",
  SHARE_POST:"SHARE_POST",
  DASHBOARD_DATA_LOADING:"DASHBOARD_DATA_LOADING",
  GET_DASHBOARD_DATA:"GET_DASHBOARD_DATA",
  RESET_DASHBOARD_DATA:"RESET_DASHBOARD_DATA",
  GOTO_VACANCYTEXT_FROM_DASHBOARD:"GOTO_VACANCYTEXT_FROM_DASHBOARD",
  GET_CURRENT_SESSION:"GET_CURRENT_SESSION",
  GET_PREVIOUS_QUESTIONS:"GET_CURRENT_QUESTIONS",
  RESET_CHATBOT_SESSION:"RESET_CHATBOT_SESSION",
  RESET_CURRENT_QUESTION:"RESET_CURRENT_QUESTION",
  ERROR_MESSAGE:"ERROR_MESSAGE",
  LOADING_SESSION:"LOADING_SESSION",
  COLOR_SELECTION:"COLOR_SELECTION",
  SET_LANGUAGE:"SET_LANGUAGE"
};

export default ActionName;

