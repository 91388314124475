import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useDispatch, useSelector } from "react-redux";
import "../job/job.scss";
import editsvg from "../../assets/img/svg/edit.svg";
import editSelected from "../../assets/img/svg/editSelected.svg";
import copysvg from "../../assets/img/svg/copy.svg";
import downloadsvg from "../../assets/img/svg/download.svg";
import sharesvg from "../../assets/img/svg/share.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import JobTitle from "../../assets/img/svg/JobTitle.svg";
import ToneofVoice from "../../assets/img/svg/ToneofVoice.svg";
import Neuromarketing from "../../assets/img/svg/Neuromarketing.svg";
import ElementsofValue from "../../assets/img/svg/ElementsofValue.svg";
import addsvg from "../../assets/img/svg/add.svg";
import sparksvg from "../../assets/img/svg/spark.svg";
import Loader from "../../components/Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Otherpopup from "./otherpopup";
import {
  editedVacancyTextData,
  getBeautifiedData,
  getSocialMediaTemplate,
  toneOfVoice,
} from "../../redux/action/chatbotAction";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Utils from "../../utils/reduxUtils";
import CancellationPopup from "../../common/CancellationPopup";
import {
  handleCopyContent,
  handleShareEmail,
  handleToneOfVoice,
} from "./helper";
import TestPdfTemplate from "./TestPdfTemplate";
import html2pdf from 'html2pdf.js';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const UpdatedVacancy = () => {
  const [customTone, setCustomTone] = useState("");
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [editedVacancyText, setEditedVacancyText] = useState(null);
  const { chatbotDataLoading, vacancyText, vacancyId } = useSelector(
    (state) => state?.jobDescriptionFileUploadReducer
  );
  const dispatch = useDispatch();
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const isEditFocus = useRef(null);
  const pdfRef = useRef(); 

  const section1Ref=useRef(null);
  const section2Ref=useRef(null);
  const section3Ref=useRef(null);
  
  const copyStoryTellingRef = useRef(null);
  const copyAboutTheFunctionRefs = useRef({});
  const copyCriteriaContentRefs = useRef({});
  const copyEmploymentConditionsContentRefs = useRef({});

  const [isPdfGettingDownloaded,setIsPdfGettingDownloaded]=useState(false);
  const [templateData, setTemplateData]=useState([]);


  const [isCancellationPopupOpen, setIsCancellationPopupOpen] = useState(false);
  const vacancyTextData =
    vacancyText[0]?.vacancyText !== undefined &&
    vacancyText[0]?.vacancyText !== null
      ? vacancyText[0]?.vacancyText
      : vacancyText[0];

      console.log('vacancyId',vacancyId);
  console.log("vacancyTextData45", vacancyTextData);

  const [aboutTheFunction, setAboutTheFunction] = useState({});
  const [criteria, setCriteria] = useState([]);
  const [employmentConditions, setEmploymentConditions] = useState([]);

  const [roleOverView, setRoleOverView] = useState({});
  const [companyOverView, setCompanyOverView] = useState({});
  const [fittingTraits, setFittingTraits] = useState([]);
  const [reasonToJoin, setReasonToJoin] = useState([]);
  const [applicationInstructions, setApplicationInstructions] = useState("");

  const [trendingTitles, setTrendingTitles] = useState([]);
  const [trendingTitlesTooltipValue, setTrendingTitlesTooltipValue] =
    useState("");
  const [elementOfValues, setElementOfValues] = useState([]);
  const [elementOfTooltipValues, setElementOfTooltipValues] = useState({});
  const [neuromarketingValues, setNeuroMarketingValues] = useState([]);
  const [neuromarketingTooltipValues, setNeuromarketingTooltipValues] =
    useState([]);
  const [storeEditedData, setStoreEditedData] = useState(
    editedVacancyText !== null ? editedVacancyText : vacancyTextData
  );
  let maxKey = null;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenToneDialog(false);
    setCustomTone("");
  };
  const handleChange = (value) => {
    if (value === "others") {
      setOpenToneDialog(true);
    }
  };
  console.log("vacancy654Text", vacancyTextData);

  //handle Tone Of voice selection
  const handleToneOfVoiceSelection = (tov) => {
    handleToneOfVoice(tov, vacancyTextData, toneOfVoice, dispatch, handleClose);
  };

  const handleOpenPopup = () => {
    setIsCancellationPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsCancellationPopupOpen(false);
  };

  const handleOption1Click = () => {
    // Perform action for Option 1
    setEditedVacancyText(null);
    setIsEditEnabled(false);
    handleClosePopup();
  };

  const handleOption2Click = () => {
    // Perform action for Option 2
    handleClosePopup();
  };

  //handle copy functionality of complete content

    const handleCompleteContentCopy = () => {
    handleCopyContent(vacancyTextData, contentRef2);
  };


//   const formatVacancyDataForClipboard = (data) => {
//     const renameKeys = {
//       Role_Details: "Role Details",
//       Employment_Condition: "Employment Conditions",
//       Job_Title: "Job Title",
//       Storytelling: "Storytelling",
//       Who_Are_We: "Who Are We",
//       Trending_Titles:"Trending Titles",
//       application_instructions:"application instructions",
//       Criteria:"The job requirements:",
//       reasons_to_join:"Just a few reasons to want to work with us",
//       Tone_of_voice:"Tone of voice",
//       Elements_of_values:"Elements of values",
//       neuromarketing_principles:"neuromarketing principles",
//       fitting_traits:"These characteristics suit you",


//       // Add more key mappings as needed
//     };
  
//     const formatValue = (value) => {
//       if (typeof value === "string") {
//         return value; // Return string as-is
//       } else if (Array.isArray(value)) {
//         if (value.length > 0 && typeof value[0] === "object") {
//           // Format array of objects
//           return value
//             .map(
//               (obj) =>
//                 `- ${Object.entries(obj)
//                   .map(([k, v]) => `${k}: ${v}`)
//                   .join(", ")}`
//             )
//             .join("\n");
//         } else {
//           // Format array of strings
//           return value.map((item) => `- ${item}`).join("\n");
//         }
//       } else if (typeof value === "object" && value !== null) {
//         // Format nested objects
//         return Object.entries(value)
//           .map(([key, val]) => `- ${key}: ${val}`)
//           .join("\n");
//       }
//       return JSON.stringify(value, null, 2); // Fallback for other types
//     };
  
//     return Object.entries(data)
//       .map(([key, value]) => {
//         const heading = renameKeys[key] || key; // Use renamed key if available
//         return `${heading}:\n${formatValue(value)}\n`;
//       })
//       .join("\n");
//   };

const formatVacancyDataForClipboard = (data) => {
    const renameKeys = {
      Role_Details: "Role Details",
      Employment_Condition: "Employment Conditions",
      Job_Title: "Job Title",
      Storytelling: "Storytelling",
      Who_Are_We: "Who Are We",
      Trending_Titles: "Trending Titles",
      application_instructions: "Do you recognize yourself in the profile? This is how you can apply for this position",
      Criteria: "The Job Requirements",
      reasons_to_join: "Just a Few Reasons to Want to Work with Us",
      Tone_of_voice: "Tone of Voice",
      Elements_of_values: "Elements of Values",
      neuromarketing_principles: "Neuromarketing Principles",
      fitting_traits: "These Characteristics Suit You",
  
      // Add more key mappings as needed
    };
  
    // Custom mapping for nested keys in Role_Details
    const nestedRenameKeys = {
        Role_Details: {
          Role_Overview: "Role Overview",
          Motivation_for_Role: "Motivation for Role",
          What_activities_can_you_expect_during_a_working_day: "Daily Activities"
        },
        Company_Overview: {
          Who_Are_We: "Who Are We",
        },
      };
      
  
    const formatValue = (value, nestedMapping = {}) => {
      if (typeof value === "string") {
        return value; // Return string as-is
      } else if (Array.isArray(value)) {
        if (value.length > 0 && typeof value[0] === "object") {
          // Format array of objects
          return value
            .map(
              (obj) =>
                `- ${Object.entries(obj)
                  .map(([k, v]) => `${k}: ${v}`)
                  .join(", ")}`
            )
            .join("\n");
        } else {
          // Format array of strings
          return value.map((item) => `- ${item}`).join("\n");
        }
      } else if (typeof value === "object" && value !== null) {
        // Format nested objects
        return Object.entries(value)
          .map(([key, val]) => {
            const nestedHeading = nestedMapping[key] || key; // Use nested renamed key if available
            return `- ${nestedHeading}: ${val}`;
          })
          .join("\n");
      }
      return JSON.stringify(value, null, 2); // Fallback for other types
    };
  
    return Object.entries(data)
      .map(([key, value]) => {
        const heading = renameKeys[key] || key; // Use renamed key if available
        const nestedMapping = nestedRenameKeys[key] || {}; // Get nested mappings for specific keys
        return `${heading}:\n${formatValue(value, nestedMapping)}\n`;
      })
      .join("\n");
  };
  

  const handleCopyToClipboard = () => {
    // Convert the JSON object to a formatted string
    // const jsonString = JSON.stringify(vacancyTextData, null, 2);
    // Format the JSON object into the desired string format
  const formattedString = formatVacancyDataForClipboard(vacancyTextData);

    // Copy the string to clipboard
    navigator.clipboard.writeText(formattedString)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        toast.error(err);
      });
  }; 

  // const handleEditData = (key, value) => {
  //     setEditedVacancyText((prevstate) => (
  //         console.log(...prevstate)
  //         {
  //         ...prevstate,
  //         [key]: value,
  //     }));

  //     setStoreEditedData((prevstate) => ({
  //         ...prevstate,
  //         [key]: value,
  //     }));
  // };

  const handleEditData = (key, value) => {
    console.log("key,value", key, value);
    setEditedVacancyText((prevstate) => {
      console.log("x5445", prevstate); // Log previous state here
      return {
        ...prevstate,
        [key]: value,
      };
    });

    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };
  console.log("editedVacancyText256", editedVacancyText);

  const handleEditRoleDetailsData = (key, value) => {
    console.log("key,value", key, value);
    setEditedVacancyText((prevstate) => {
      console.log("x5445", prevstate); // Log previous state here
      return {
        ...prevstate,
        Role_Details: {
          ...prevstate?.Role_Details,
          [key]: value,
        },
      };
    });

    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  const handleEditCompanyOverviewData = (key, value) => {
    console.log("key,value", key, value);
    setEditedVacancyText((prevstate) => {
      console.log("x5445", prevstate); // Log previous state here
      return {
        ...prevstate,
        Company_Overview: {
          ...prevstate?.Company_Overview,
          [key]: value,
        },
      };
    });

    setStoreEditedData((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  //   const handleCriteriaEditData = (key, value) => {
  //     console.log("key,value", key, value);
  //     setEditedVacancyText((prevstate) => {
  //       console.log("x5445", prevstate);
  //       return {
  //         ...prevstate,
  //         [key]: value,
  //       };
  //     });

  //     setStoreEditedData((prevstate) => ({
  //       ...prevstate,
  //       [key]: value,
  //     }));
  //   };

  //   const handleFunctionEditData = (key, value) => {
  //     // console.log("key12", key);
  //     // setEditedVacancyText((prevState) => ({
  //     //   ...prevState,
  //     //   About_The_Function: {
  //     //     ...prevState["About_The_Function"],
  //     //     [key]: value,
  //     //   },
  //     // }));
  //   };

  const handleFunctionEditData = (key, updatedValues) => {
    setEditedVacancyText((prevState) => {
      return {
        ...prevState,
        [key]: updatedValues, // Update the key with the array of updated values
      };
    });

    // Optionally update another state or store, e.g., setStoreEditedData
    setStoreEditedData((prevState) => {
      return {
        ...prevState,
        [key]: updatedValues,
      };
    });
  };

  //   const handleCriteriaEditData = (key, value) => {
  //     console.log("key,value", key, value);
  //     setEditedVacancyText((prevstate) => {
  //       console.log("x5445", prevstate); // Log previous state here
  //       return {
  //         ...prevstate,
  //         Criteria[key]: value,
  //       };
  //     });

  //     setStoreEditedData((prevstate) => ({
  //       ...prevstate,
  //       Criteria[key]: value,
  //     }));
  //   };

  //   const handleCriteriaEditData = (key, updatedValue) => {
  //     setEditedVacancyText((prevText) => ({
  //       ...prevText,
  //       Criteria: prevText.Criteria.map((item) => {
  //         const [currentKey, currentValue] = Object.entries(item)[0];
  //         if (currentKey === key) {
  //           // Update only the value of the matched key
  //           return { [currentKey]: updatedValue };
  //         }
  //         return item; // Return unchanged items
  //       }),
  //     }));

  //     setStoreEditedData((prevText) => ({
  //       ...prevText,
  //       Criteria: prevText.Criteria.map((item) => {
  //         const [currentKey, currentValue] = Object.entries(item)[0];
  //         if (currentKey === key) {
  //           // Update only the value of the matched key
  //           return { [currentKey]: updatedValue };
  //         }
  //         return item;
  //       }),
  //     }));
  //   };

  const handleCriteriaEditData = (key, updatedValue) => {
    console.log("updatedValue", key, updatedValue);
    setEditedVacancyText((prevText) => {
      const updatedCriteria = prevText.Criteria.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Criteria: updatedCriteria,
      };
    });

    setStoreEditedData((prevText) => {
      const updatedCriteria = prevText.Criteria.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Criteria: updatedCriteria,
      };
    });
  };

  const handleEmploymentEditData = (key, updatedValue) => {
    console.log("updatedValue", key, updatedValue);
    setEditedVacancyText((prevText) => {
      const updatedCriteria = prevText.Employment_Condition.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Employment_Condition: updatedCriteria,
      };
    });

    setStoreEditedData((prevText) => {
      const updatedCriteria = prevText.Employment_Condition.map((item) => {
        const [currentKey] = Object.entries(item)[0];
        if (currentKey === key) {
          return { [currentKey]: updatedValue };
        }
        return item;
      });

      return {
        ...prevText,
        Employment_Condition: updatedCriteria,
      };
    });
  };
  console.log("editedVacancyText25", editedVacancyText);
//Get Social Media data

// useEffect(()=>{
//   dispatch(getSocialMediaTemplate(vacancyId,setTemplateData))
// },
// [])


console.log('templateData58',templateData);

  //Save Edited Data
  const handleSubmit = () => {
    const payload = {
      id: vacancyId,
      vacancyText: editedVacancyText,
    };
    console.log("editedVacancyText", payload);
    dispatch({
      type: Utils.ActionName.CHATBOTDATA,
      payload: editedVacancyText,
    });
    dispatch(
      editedVacancyTextData(payload, setEditedVacancyText, setIsEditEnabled)
    );
    setStoreEditedData(editedVacancyText);
    setIsEditEnabled(false);
    // toast.success("Text Edited Successfully");
  };

  //handle Edit Mode
  const handleEditMode = () => {
    if (!isEditEnabled) {
      setEditedVacancyText(vacancyTextData);
      setStoreEditedData(vacancyTextData); // Set the text only when enabling edit mode
    }
    setIsEditEnabled(!isEditEnabled); // Toggle the edit mode state
  };

  //handle Job description share functionality
  const handleShareByEmail = () => {
    handleShareEmail(vacancyId, vacancyTextData);
  };


  useEffect(() => {
    if (vacancyTextData) {
      let maxLength = 0;
      let maxKeyValue = "";
      // Iterate through the object to find the array with the maximum length
      // for (let key in vacancyTextData) {
      //     if (
      //         Array.isArray(vacancyTextData[key]) &&
      //         vacancyTextData[key].length > maxLength
      //     ) {
      //         maxKey = key;
      //         maxLength = vacancyTextData[key].length;
      //         maxKeyValue = vacancyTextData[maxKey];
      //     }
      // }
      setRoleOverView(vacancyTextData?.Role_Details);
      setCompanyOverView(vacancyTextData?.Company_Overview);
      setFittingTraits(vacancyTextData?.fitting_traits);
      setReasonToJoin(vacancyTextData?.reasons_to_join);
      setApplicationInstructions(vacancyTextData?.application_instructions);

      setAboutTheFunction(vacancyTextData?.About_The_Function);
      setCriteria(vacancyTextData?.Criteria);
      setEmploymentConditions(vacancyTextData?.Employment_Condition);
      const Keys = Object.keys(vacancyTextData?.Trending_Titles);
      const trendingTitles = vacancyTextData?.Trending_Titles;
      const datas = trendingTitles.map((data) => Object.keys(data).join(","));
      console.log("datas", datas);
      setTrendingTitles(datas.join(", "));
      const trendingValues = trendingTitles
        ?.map((data) => Object.values(data))
        .flat()
        .join(" ");
      console.log("trendingValues", trendingValues);
      const sentencesArray = trendingValues.split(".");

      // Step 2: Remove empty entries and rejoin with <br /> for line breaks
      const formattedString = sentencesArray
        .filter((sentence) => sentence.trim() !== "") // Filter out any empty strings from the split
        .map((sentence) => sentence.trim()) // Trim each sentence
        .join(".<br />");
      setTrendingTitlesTooltipValue(formattedString);
      // Initializing an array to store the tooltip values
      const trendingTitlesTooltipValue = [];

      // Iterating over the keys to extract and append values
      Keys.forEach((ele) => {
        // Access the value associated with the current key and store it in an array
        trendingTitlesTooltipValue.push(vacancyTextData?.Trending_Titles[ele]);
      });

      const elements = vacancyTextData?.Elements_of_values || [];
      const keysArray = [];
      const valuesArray = [];

      elements.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          keysArray.push(key);
          valuesArray.push(value);
        });
      });

      console.log("valuesArray", valuesArray);
      setElementOfValues(keysArray.join(", "));
      setElementOfTooltipValues(valuesArray.join("<br />"));

      const neuromarketing = vacancyTextData?.neuromarketing_principles || [];
      const neuromarketingArray = [];
      const neuromarketingValuesArray = [];

      neuromarketing.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          neuromarketingArray.push(key);
          neuromarketingValuesArray.push(value);
        });
      });
      setNeuroMarketingValues(neuromarketingArray.join(", "));
      setNeuromarketingTooltipValues(neuromarketingValuesArray.join("<br />"));
    }
    setEditedVacancyText(vacancyTextData);
  }, [vacancyTextData]);

  //handleBeautifyTemplate
  const handleBeautifyTemplate = () => {
    const payload = {
      structured_output: JSON.stringify(storeEditedData),
    };
    dispatch(getBeautifiedData(payload));
  };

  //Focus Job Title Field when edit is enabled
  useEffect(() => {
    if (isEditEnabled && isEditFocus?.current) {
      isEditFocus?.current?.focus();
    }
  }, [isEditEnabled]);

  //Story Telling Content Copy
  const handleCopyStoryTellingContent = () => {
    console.log("copy clicked");
    if (copyStoryTellingRef.current) {
      const textToCopy =
        editedVacancyText?.Storytelling !== undefined
          ? editedVacancyText?.Storytelling
          : vacancyTextData?.Storytelling;

      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    }
  };

  //handle About the function Content
  const handleCopyAboutTheFunctionContent = () => {
    // Collect all the text from 'About_The_Function' object
    let textToCopy = Object.keys(aboutTheFunction)
      .map((maxKey) => {
        const content =
          editedVacancyText?.["About_The_Function"]?.[maxKey] ||
          vacancyTextData?.["About_The_Function"]?.[maxKey];
        return content?.join("\n") || ""; // Join each array by newlines
      })
      .join("\n\n"); // Add extra newlines between different sections

    console.log("textToCopy:", textToCopy);

    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    } else {
      console.log("No content available to copy.");
    }
  };

  //handle Criteria Content Copy

  const handleCopyCriteriaContent = () => {
    // Collect all the text from 'About_The_Function' object
    let textToCopy = Object.keys(criteria)
      .map((maxKey) => {
        const content =
          editedVacancyText?.["Criteria"]?.[maxKey] ||
          vacancyTextData?.["Criteria"]?.[maxKey];
        return content?.join("\n") || ""; // Join each array by newlines
      })
      .join("\n\n"); // Add extra newlines between different sections

    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    } else {
      console.log("No content available to copy.");
    }
  };

  //handle employment conditions content copy
  const handleCopyEmploymentConditionsContent = () => {
    // Collect all the text from 'About_The_Function' object
    let textToCopy = Object.keys(employmentConditions)
      .map((maxKey) => {
        const content =
          editedVacancyText?.["Employment_Condition"]?.[maxKey] ||
          vacancyTextData?.["Employment_Condition"]?.[maxKey];
        return content?.join("\n") || ""; // Join each array by newlines
      })
      .join("\n\n"); // Add extra newlines between different sections
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        toast.success("Text copied to clipboard!");
      });
    } else {
      console.log("No content available to copy.");
    }
  };

  //Copy Job Title Content 
  const handleCopyJobTitleContents = () => {
    const jobTitle =
      editedVacancyText?.["Job_Title"] ?? vacancyTextData?.["Job_Title"];

    if (jobTitle) {
      navigator.clipboard.writeText(jobTitle).then(() => {
        toast.success("Job Title copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Job Title:");
      });
    } else {
      alert("No Job Title to copy!");
    }
  };
 
//Copy StoryTelling Content

const handleCopyStoryTellingContents = () => {
    const storyTelling =
      editedVacancyText?.["Storytelling"] ?? vacancyTextData?.["Storytelling"];

    if (storyTelling) {
      navigator.clipboard.writeText(storyTelling).then(() => {
        toast.success("StoryTelling copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy storyTelling");
      });
    } else {
      alert("No storyTelling to copy!");
    }
  };

//Copy Who we are
const handleWhoWeAreContent = () => {
    const companyOverview =
      editedVacancyText?.Company_Overview["Who_Are_We"] ?? vacancyTextData?.Company_Overview["Who_Are_We"];

    if (companyOverview) {
      navigator.clipboard.writeText(companyOverview).then(() => {
        toast.success("Company Overview copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Company Overview");
      });
    } else {
      alert("No Company Overview to copy!");
    }
  };

  //Copy RoleDetails
  const handleRoleDetails=(key)=>{
    console.log("key",key)
   let companyOverview;
    switch (key) {
        case 'Role_Overview':
            companyOverview =
            editedVacancyText?.Role_Details["Role_Overview"] ?? vacancyTextData?.Role_Details["Role_Overview"];
            break;
        case 'Motivation_for_Role':
            companyOverview =
            editedVacancyText?.Role_Details["Motivation_for_Role"] ?? vacancyTextData?.Role_Details["Motivation_for_Role"];
            break;
        case 'What_activities_can_you_expect_during_a_working_day?':
            companyOverview =
            editedVacancyText?.Role_Details["What_activities_can_you_expect_during_a_working_day?"] ?? vacancyTextData?.Role_Details["What_activities_can_you_expect_during_a_working_day?"];
            break;    
            
    
        default:
            break;
    }
    // const companyOverview =
    // editedVacancyText?.Company_Overview["Who_Are_We"] ?? vacancyTextData?.Company_Overview["Who_Are_We"];

  if (companyOverview) {
    navigator.clipboard.writeText(companyOverview).then(() => {
      toast.success("Role Details copied to clipboard!");
    }).catch((err) => {
      toast.error("Failed to copy Role Details");
    });
  } else {
    alert("No Role Details to copy!");
  }
  }

  console.log("Criteria",editedVacancyText?.Criteria);
  
  //Copy Job Requirements
  const handleJobRequirementsContents = () => {
    const resultantItem=criteria?.map((item)=>{
        const [key] = Object.entries(item)[0];
        const result= criteria?.find(
            (item) => Object.keys(item)[0] === key
          )?.[key] || "";
          return `${key}: ${result}`;
        }).join("\n");

    if (resultantItem) {
      navigator.clipboard.writeText(resultantItem).then(() => {
        toast.success("Job requirements copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Job requirements");
      });
    } else {
      alert("No Job requirements to copy!");
    }
  };

  //Copy characteristics 

  const handleCharacteristicsContent = () => {
    const Characteristics =
    (editedVacancyText?.["fitting_traits"] ||
    vacancyTextData?.["fitting_traits"]
  )?.join("\n") || ""

    if (Characteristics) {
      navigator.clipboard.writeText(Characteristics).then(() => {
        toast.success("Characteristics copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Characteristics");
      });
    } else {
      alert("No Characteristics to copy!");
    }
  };

//Copy Employment Condition
const handleEmploymentConditionsContent = () => {
    const resultantItem=employmentConditions?.map((item)=>{
        const [key] = Object.entries(item)[0];
        const result= employmentConditions?.find(
            (item) => Object.keys(item)[0] === key
          )?.[key] || "";
          return `${key}: ${result}`;
        }).join("\n");

    if (resultantItem) {
      navigator.clipboard.writeText(resultantItem).then(() => {
        toast.success("Employment Condition copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Employment Condition");
      });
    } else {
      alert("No Employment Condition to copy!");
    }
  };

//Copy Few Reasons to work
const handleReasonsToWorkContent = () => {
    const reasonToJoin =
    (editedVacancyText?.["reasons_to_join"] ||
    vacancyTextData?.["reasons_to_join"]
  )?.join("\n") || ""

    if (reasonToJoin) {
      navigator.clipboard.writeText(reasonToJoin).then(() => {
        toast.success("Reasons to join copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Reasons to join");
      });
    } else {
      alert("No Reasons to join content to copy!");
    }
  };

//Copy application instructions
const handleCopyApplicationInstructions = () => {
    const companyOverview =
      editedVacancyText?.["application_instructions"] ?? vacancyTextData?.["application_instructions"];

    if (companyOverview) {
      navigator.clipboard.writeText(companyOverview).then(() => {
        toast.success("Company_Overview copied to clipboard!");
      }).catch((err) => {
        toast.error("Failed to copy Company_Overview");
      });
    } else {
      alert("No Company_Overview to copy!");
    }
  };

  //Handle VacancyText Download

// const handleVacancyTextDownload = () => {
//   const element = pdfRef.current;

//   if (element) {
//       const options = {
//           margin: 10, // Adjust margin as necessary
//           filename: 'Job_Vacancy_Text.pdf',
//           image: { type: 'png', quality: 1 },
//           html2canvas: { scale: 2 }, // Capture with higher quality
//           jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
//       };

//       // Use html2pdf to generate and download the PDF
//       html2pdf().from(element).set(options).save();
//   }
// };


// const handleVacancyTextDownload = () => {
//   const element = pdfRef.current;

//   if (element) {
//     // Temporarily make the element visible before capturing
//     element.style.display = 'block';
//     element.style.position = 'static';  // Reset position so it can be rendered
//     // setIsPdfGettingDownloaded(true);
//     const options = {
//       margin: 10,
//       filename: 'Job_Vacancy_Text.pdf',
//       image: { type: 'png', quality: 1 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
//     };

//     html2pdf()
//       .from(element)
//       .set(options)
//       .toPdf()
//       .get('pdf')
//       .then((pdfDoc) => {
//         // Restore the hidden state after rendering
//         element.style.display = 'none';
//         element.style.position = 'absolute';
//         element.style.left = '-9999px';  // Hide again after rendering

//         // Save the PDF
//         pdfDoc.save();
//         toast.success('Pdf downloaded successfully');
//         setIsPdfGettingDownloaded(false);
//       });
//   }
// };


const handleVacancyTextDownload = async() => {
 
  await dispatch(getSocialMediaTemplate(vacancyId, setTemplateData));
  setTimeout(() => {
    generatePdf(); // Call your PDF generation logic after rendering
  }, 1000);
 
  
};

function generatePdf(){
  const element = pdfRef.current;
  if (element) {
    element.style.display = 'block';
    element.style.position = 'static';

    const options = {
      margin: 10,
      filename: 'Job_Vacancy_Text.pdf',
      image: { type: 'png', quality: 1 },
      html2canvas: {
        scale: 2,
        useCORS: true, // Enable cross-origin resource sharing
        onclone: (clonedDoc) => {
          // Ensure images are loaded in the cloned document
          const imgs = clonedDoc.querySelectorAll('img');
          imgs.forEach((img) => {
            if (!img.complete) {
              img.onload = () => console.log('Image loaded');
              img.onerror = () => console.error('Image load error', img.src);
            }
          });
        },
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdfDoc) => {
        element.style.display = 'none';
        element.style.position = 'absolute';
        element.style.left = '-9999px';

        pdfDoc.save();
        toast.success('Pdf downloaded successfully');
        setIsPdfGettingDownloaded(false);
      });
  }

}



return (
    <Box>
      {chatbotDataLoading ? (
        <Loader />
      ) : (
        // isPdfGettingDownloaded? <TestPdfTemplate ref={pdfRef}/>:
        <>
          <Otherpopup
            open={openToneDialog}
            customTone={customTone}
            setCustomTone={setCustomTone}
            handleClose={handleClose}
          ></Otherpopup>
          <CancellationPopup
            open={isCancellationPopupOpen}
            handleClose={handleClosePopup}
            title={"Confirmation Popup"}
            question={"Are You Sure You Want To Discard?"}
            option1={"Yes"}
            option2={"No"}
            onOption1Click={handleOption1Click}
            onOption2Click={handleOption2Click}
          />
          <Header />
          <Box className="py-2 mt-10">
            <Grid container spacing={2} className="px-5">
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className=" py-2">
                <Typography variant="h2" className="fs-32 fw-600 px-4">
                  Job Vacancy Text
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className=" py-2 flex-wrap justify-content-end"
              >
                <Box className="gap-10 flex-wrap">
                  <Box className="flex-wrap justify-content-end">
                    {isEditEnabled ? (
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={editSelected}
                        onClick={handleEditMode}
                        style={{ cursor: "pointer", borderRadius: "5px" }}
                      />
                    ) : (
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={editsvg}
                        onClick={handleEditMode}
                        style={{ cursor: "pointer", borderRadius: "5px" }}
                      />
                    )}
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={copysvg}
                      onClick={handleCopyToClipboard}
                      style={{ cursor: "pointer" }}
                    />
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    {/* <img className='my-auto' width={27} height={27} src={downloadsvg} style={{cursor:"pointer"}}/> */}

                   


                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={downloadsvg}
                      style={{ cursor: "pointer" }}
                      alt="Download PDF"
                      title="Download PDF"
                      onClick={handleVacancyTextDownload}
                    />

                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <img
                      className="my-auto"
                      width={27}
                      height={27}
                      src={sharesvg}
                      style={{ cursor: "pointer" }}
                      onClick={handleShareByEmail}
                    />
                    <Divider
                      className="mx-2"
                      orientation="vertical"
                      flexItem
                      sx={{ border: "1px solid black" }}
                    />
                    <Box className="flex-wrap gap-10">
                      <div>
                        <Button
                          className="primary-btn-outline gap-10"
                          sx={{ minWidth: "fit-content" }}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <img src={sparksvg} />
                          Tone of Voice <KeyboardArrowDownIcon />
                        </Button>

                        <Menu
                          className="toneofvoice"
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("friendlyTone")
                            }
                          >
                            Friendly Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("innovativeTone")
                            }
                          >
                            Innovative Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("professionalTone")
                            }
                          >
                            Professional Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("inspirationalTone")
                            }
                          >
                            Inspirational Tone
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleToneOfVoiceSelection("informativeTone")
                            }
                          >
                            Informative Tone
                          </MenuItem>
                          <MenuItem onClick={() => handleChange("others")}>
                            Others
                          </MenuItem>
                        </Menu>
                      </div>

                      <Link
                        to={`/job/upload/${vacancyId}`}
                        state={{ editedData: editedVacancyText }}
                      >
                        {" "}
                        <Button
                          className="primary-btn-outline gap-10"
                          sx={{ minWidth: "fit-content" }}
                        >
                          <img src={addsvg} />
                          Generate the Social Media Template{" "}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={9} className="">
                <Box className="vacancy-content" ref={contentRef1}>
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Job Title
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleCopyJobTitleContents}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    ref={isEditFocus}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Job Title"
                    style={{ width: "100% " }}
                    value={
                      editedVacancyText?.["Job_Title"] !== undefined
                        ? editedVacancyText?.["Job_Title"]
                        : vacancyTextData?.["Job_Title"]
                    }
                    disabled={!isEditEnabled}
                    onChange={(e) =>
                      handleEditData("Job_Title", e.target.value)
                    }
                  />
                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Story Telling
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleCopyStoryTellingContents}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    ref={copyStoryTellingRef}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Story Telling"
                    style={{ width: "100% " }}
                    disabled={!isEditEnabled}
                    value={
                      editedVacancyText?.["Storytelling"] !== undefined
                        ? editedVacancyText?.["Storytelling"]
                        : vacancyTextData?.["Storytelling"]
                    }
                    onChange={(e) =>
                      handleEditData("Storytelling", e.target.value)
                    }
                  />

                  {Object.entries(companyOverView).map(([key, value]) => (
                    <div key={key}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {key === "Who_Are_We" && "Who are we?"}
                        <span>
                          <img
                            className="my-auto"
                            width={27}
                            height={27}
                            src={copysvg}
                            onClick={handleWhoWeAreContent}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </Typography>
                      <TextareaAutosize
                        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                        aria-label="minimum height"
                        minRows={1}
                        value={
                          editedVacancyText?.Company_Overview?.[key] !==
                          undefined
                            ? editedVacancyText?.Company_Overview?.[key]
                            : vacancyTextData?.Company_Overview?.[key]
                        }
                        onChange={(e) =>
                          handleEditCompanyOverviewData(key, e.target.value)
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                        disabled={!isEditEnabled}
                      />
                    </div>
                  ))}

                  {Object.entries(roleOverView).map(([key, value]) => (
                    <div key={key}>
                      <Typography
                        className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                        variant="h3"
                      >
                        {key === "Role_Overview"
                          ? "What does the position entail?"
                          : key === "Motivation_for_Role"
                          ? "Why are you going to do this?"
                          : key ===
                            "What_activities_can_you_expect_during_a_working_day?"
                          ? "What activities can you expect during a working day?"
                          : key}

                        <span>
                          <img
                            className="my-auto"
                            width={27}
                            height={27}
                            src={copysvg}
                            onClick={()=>handleRoleDetails(key)}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </Typography>
                      <TextareaAutosize
                        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                        aria-label="minimum height"
                        minRows={1}
                        value={
                          editedVacancyText?.Role_Details?.[key] !== undefined
                            ? editedVacancyText?.Role_Details?.[key]
                            : vacancyTextData?.Role_Details?.[key]
                        }
                        onChange={(e) =>
                          handleEditRoleDetailsData(key, e.target.value)
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                        disabled={!isEditEnabled}
                      />
                    </div>
                  ))}

                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    The job requirements:
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleJobRequirementsContents}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  {(criteria || []).map((criteriaItem) => {
                    const [key, value] = Object.entries(criteriaItem)[0];
                    return (
                      <div key={key}>
                        {isEditEnabled ? (
                          <div className="fs-16 text-gray-c1 fw-500 mt-10 no-border job">
                            <strong>{key}:</strong>
                            <TextareaAutosize
                              className="ml-10"
                              aria-label="minimum height"
                              minRows={1}
                              placeholder="Enter Job Criteria"
                              style={{ width: "calc(100% - 50px)" }}
                              //   value={value || ""}
                              value={
                                editedVacancyText?.Criteria?.find(
                                  (item) => Object.keys(item)[0] === key
                                )?.[key] || ""
                              }
                              onChange={(e) =>
                                handleCriteriaEditData(key, e.target.value)
                              }
                            />
                          </div>
                        ) : (
                            value!=="" &&
                          <List
                            style={{
                              listStyleType: "disc",
                              paddingLeft: "20px",
                            }}
                          >
                            <ListItem
                              key={key}
                              className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                              style={{
                                display: "list-item",
                                listStylePosition: "inside",
                                color: "black",
                              }}
                            >
                              {/* {`${key}: ${value}`} */}
                            
                                <>
                                  <span style={{ fontWeight: "bold" }}>
                                    {key}
                                  </span>
                                  <span>: {value}</span>
                                </>
                            
                            </ListItem>
                          </List>
                        )}
                      </div>
                    );
                  })}

                  {/* {criteria.map((criteriaItem, index) => {
                    const [key, value] = Object.entries(criteriaItem)[0];
                    return <div key={index}>
                      {isEditEnabled    
                        ? (
                            <TextareaAutosize
                              className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                              aria-label="minimum height"
                              minRows={1}
                              placeholder="Enter Job Criteria"
                              style={{ width: "100%" }}
                            //   value={criteriaItem}
                            //   onChange={(e) => {
                            //     const [key, ...valueParts] =
                            //       e.target.value.split(":");
                            //     const value = valueParts.join(":").trim();
                            //     handleCriteriaEditData(index, {
                            //       [key.trim()]: value,
                            //     });

                            //   }}
                              value={`${key}:${value}`}
                              onChange={(e) => handleCriteriaEditData(index, e.target.value)}
                            />
                          )
                        :  (
                            <List
                              style={{
                                listStyleType: "disc",
                                paddingLeft: "20px",
                              }}
                            >
                              <ListItem
                                key={index}
                                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                style={{
                                  display: "list-item",
                                  listStylePosition: "inside",
                                  color: "black",
                                }}
                              >
                                {`${key}:${value}`}
                              </ListItem>
                            </List> 
                          )}
                    </div>
                  }
                  )} */}

                  {/* {criteria.map((criteriaItem, index) => {
                    const [key, value] = Object.entries(criteriaItem)[0]; // Extract key and value

                    return (
                      <div key={index}>
                        {isEditEnabled
                          ? value !== "" && (
                              <TextareaAutosize
                                className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                aria-label="minimum height"
                                minRows={1}
                                placeholder="Enter Job Criteria"
                                style={{ width: "100%" }}
                                value={`${key}: ${value}`}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const colonIndex = inputValue.indexOf(":");
                                  if (colonIndex > 0) {
                                    const updatedKey = inputValue
                                      .slice(0, colonIndex)
                                      .trim();
                                    const updatedValue = inputValue
                                      .slice(colonIndex + 1)
                                      .trim();
                                    handleCriteriaEditData(index, {
                                      [updatedKey]: updatedValue,
                                    });
                                  } else {
                                    // Handle invalid input format (no colon)
                                    console.warn(
                                      "Invalid format. Please use 'key: value' format."
                                    );
                                  }
                                }}
                              />
                            )
                          : value !== "" && (
                              <List
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "20px",
                                }}
                              >
                                <ListItem
                                  key={index}
                                  className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                  style={{
                                    display: "list-item",
                                    listStylePosition: "inside",
                                    color: "black",
                                  }}
                                >
                                  {`${key}: ${value}`}
                                </ListItem>
                              </List>
                            )}
                      </div>
                    );
                  })} */}

                  {/* {fittingTraits.map((maxKey) => ( */}
                  <div>
                    <Typography
                      className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                      variant="h3"
                    >
                      These characteristics suit you:
                      <span>
                        <img
                          className="my-auto"
                          width={27}
                          height={27}
                          src={copysvg}
                         onClick={handleCharacteristicsContent}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </Typography>

                    {isEditEnabled ? (
                      <TextareaAutosize
                        // ref={(el) => (copyAboutTheFunctionRefs.current[maxKey] = el)}
                        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                        aria-label="minimum height"
                        minRows={1}
                        placeholder="Enter About The Function"
                        style={{ width: "100%" }}
                        value={
                          (
                            editedVacancyText?.["fitting_traits"] ||
                            vacancyTextData?.["fitting_traits"]
                          )?.join("\n") || ""
                        }
                        onChange={(e) =>
                          handleFunctionEditData(
                            "fitting_traits",
                            e.target.value.split("\n")
                          )
                        }
                      />
                    ) : (
                      <List
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "20px",
                          color: "black !important",
                        }}
                      >
                        {fittingTraits?.map((item, index) => (
                          <ListItem
                            key={index}
                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                            // ref={(el) => (copyAboutTheFunctionRefs.current[maxKey] = el)}
                            style={{
                              display: "list-item",
                              listStylePosition: "inside",
                              color: "black",
                            }}
                          >
                            {item}
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </div>
                  {/* ))} */}

                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    These are your employment conditions:
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleEmploymentConditionsContent}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>

                  {/* {
                                        employmentConditions.map((employmentCriteria, index) => (
                                            <div key={index}>
                                                {isEditEnabled ? (
                                                    Object.values(employmentCriteria)[0] !== "" &&
                                                    <TextareaAutosize
                                                        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                                        aria-label="minimum height"
                                                        minRows={1}
                                                        placeholder="Enter Job Criteria"
                                                        style={{ width: "100%" }}
                                                        value={Object.values(employmentCriteria)[0] || ""}
                                                        onChange={(e) =>
                                                            handleCriteriaEditData(
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    Object.values(employmentCriteria)[0] !== "" &&
                                                    <List style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                                        <ListItem
                                                            key={index}
                                                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                                                            style={{ display: 'list-item', listStylePosition: 'inside', color: "black" }}
                                                        >
                                                            {Object.values(employmentCriteria)[0]}
                                                        </ListItem>
                                                    </List>
                                                )}
                                            </div>
                                        ))
                                    } */}

                  {(employmentConditions || [])?.map((employmentCriteria) => {
                    const [key, value] = Object.entries(employmentCriteria)[0];

                    console.log("value12", key, value); // Destructure here properly
                    return (
                      <div key={key}>
                        {isEditEnabled ? (
                          <div className="fs-16 text-gray-c1 fw-500 mt-10 no-border job">
                            <strong>{key}:</strong>
                            <TextareaAutosize
                              className="ml-10"
                              aria-label="minimum height"
                              minRows={1}
                              placeholder="Enter Employment Condition"
                              style={{ width: "100%" }}
                              value={
                                editedVacancyText?.Employment_Condition?.find(
                                  (item) => Object.keys(item)[0] === key
                                )?.[key] || ""
                              }
                              onChange={(e) =>
                                handleEmploymentEditData(key, e.target.value)
                              } // Ensure proper handler
                            />
                          </div>
                        ) : (
                            
                                value!=="" &&           
                                <List
                            style={{
                              listStyleType: "disc",
                              paddingLeft: "20px",
                            }}
                          >
                            <ListItem
                              key={key}
                              className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                              style={{
                                display: "list-item",
                                listStylePosition: "inside",
                                color: "black",
                              }}
                            >
                              {/* {`${key}: ${value}`} */}
                             
                                
                                  <span style={{ fontWeight: "bold" }}>
                                    {key}
                                  </span>
                                  <span>: {value}</span>
                                
                              
                            </ListItem>
                          </List>
                            
                
                        )}
                      </div>
                    );
                  })}

                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Just a few reasons to want to work with us
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleReasonsToWorkContent}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>

                  {/* {reasonToJoin.map((maxKey,index) => ( */}
                  <div>
                    {isEditEnabled ? (
                      <TextareaAutosize
                        // ref={(el) => (copyAboutTheFunctionRefs.current[maxKey] = el)}
                        className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                        aria-label="minimum height"
                        minRows={1}
                        placeholder="Enter About The Function"
                        style={{ width: "100%" }}
                        value={
                          (
                            editedVacancyText?.["reasons_to_join"] ||
                            vacancyTextData?.["reasons_to_join"]
                          )?.join("\n") || ""
                        }
                        onChange={(e) =>
                          handleFunctionEditData(
                            "reasons_to_join",
                            e.target.value.split("\n")
                          )
                        }
                        //                 value={
                        //   Array.isArray(editedVacancyText?.["reasons_to_join"]?.[index])
                        //     ? editedVacancyText["reasons_to_join"][index].join("\n")
                        //     : Array.isArray(vacancyTextData?.["reasons_to_join"]?.[index])
                        //     ? vacancyTextData["reasons_to_join"][index].join("\n")
                        //     : ""
                        // }
                        // onChange={(e) =>
                        //   handleFunctionEditData(
                        //     "reasons_to_join",
                        //     {
                        //       ...editedVacancyText?.["reasons_to_join"],
                        //       [index]: e.target.value.split("\n"),
                        //     }
                        //   )
                        // }
                      />
                    ) : (
                      <List
                        style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      >
                        {reasonToJoin?.map((item, index) => (
                          <ListItem
                            key={index}
                            className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                            // ref={(el) => (copyAboutTheFunctionRefs.current[maxKey] = el)}
                            style={{
                              display: "list-item",
                              listStylePosition: "inside",
                              color: "black",
                            }}
                          >
                            {item}
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </div>
                  {/* ))}  */}

                  <Typography
                    className="fs-18 text-color-c1 fw-700 mt-20 d-flex justify-content-between"
                    variant="h3"
                  >
                    Do you recognize yourself in the profile? This is how you
                    can apply for this position
                    <span>
                      <img
                        className="my-auto"
                        width={27}
                        height={27}
                        src={copysvg}
                        onClick={handleCopyApplicationInstructions}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  </Typography>
                  <TextareaAutosize
                    ref={copyStoryTellingRef}
                    className="fs-16 text-gray-c1 fw-500 mt-10 no-border job"
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Enter Story Telling"
                    style={{ width: "100% " }}
                    disabled={!isEditEnabled}
                    // value={
                    //     editedVacancyText?.Storytelling !== undefined
                    //         ? editedVacancyText?.Storytelling
                    //         : vacancyTextData?.Storytelling
                    // }
                    // value={applicationInstructions}
                    value={
                      editedVacancyText?.["application_instructions"] !==
                      undefined
                        ? editedVacancyText?.["application_instructions"]
                        : vacancyTextData?.["application_instructions"]
                    }
                    onChange={(e) =>
                      handleEditData("application_instructions", e.target.value)
                    }
                  />
                </Box>

                <TestPdfTemplate ref={pdfRef} templateData={templateData}/>
                <Box className="flex-wrap justify-content-between my-30">
                  <Button
                    className="primary-btn "
                    onClick={handleBeautifyTemplate}
                  >
                    Beautify Template{" "}
                  </Button>
                  <Box>
                    <Button
                      onClick={() => {
                        handleOpenPopup();
                      }}
                      className="primary-btn-outline mr-10"
                      sx={{ minWidth: "144px" }}
                    >
                      Discard
                    </Button>
                    <Button
                      disabled={!isEditEnabled}
                      onClick={handleSubmit}
                      className="primary-btn"
                      sx={{ minWidth: "144px" }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                <Box
                  sx={{
                    background: "#FFF3E9",
                    height: "100%",
                    padding: "28px 24px ",
                  }}
                  ref={contentRef2}
                >
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={JobTitle} />
                        &nbsp; Job Title
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: trendingTitlesTooltipValue,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {trendingTitles}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ElementsofValue} />
                        &nbsp; Elements of Value
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: elementOfTooltipValues,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {elementOfValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={Neuromarketing} />
                        &nbsp; Neuromarketing{" "}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: neuromarketingTooltipValues,
                            }}
                          />
                        }
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {neuromarketingValues}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box className="d-flex justify-content-between">
                      <Typography
                        variant="h4"
                        className="fs-18 text-color-c1 fw-600 text-start"
                      >
                        <img width={24} height={24} src={ToneofVoice} />
                        &nbsp; Tone of Voice{" "}
                      </Typography>
                      <Tooltip
                        className="text-orange"
                        title={vacancyTextData?.Tone_of_voice}
                      >
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </Box>
                    <Box
                      className="bg-white mt-10 mb-10"
                      sx={{ padding: "12px 14px" }}
                    >
                      <Typography className="fs-16 fw-500 color-primary">
                        {vacancyTextData?.Tone_of_voice}{" "}
                      </Typography>
                    </Box>
                  </Box>
                 
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default UpdatedVacancy;
