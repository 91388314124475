import React from 'react';
import {toast} from 'react-toastify';
// import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(this.props.navigate,"props");
    this.props.navigate('/chatbot-update'); 
    toast.error("There was a problem generating the job vacancy. Please try again.");
    console.error('Error caught by ErrorBoundary:', error, info);
  }

  


  render() {
    if (this.state.hasError) {
      return null; // Don't render anything if there's an error
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
